
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Button from '@components/atoms/__DEPRECATED__/Button';
import LINKS from '@constants/links';
import createSwrFallback from '@helpers/fallback';
import useAppRouter from '@hooks/useAppRouter';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { ButtonWrapper, Container, HeadingWrapper } from '../components/templates/ErrorPages/ErrorPages.styles';
const Error404 = () => {
    const { t } = useTranslation('errorPages');
    const { push } = useAppRouter();
    return (<>
      <Head>
        <title>{t('404->pageTitle')}</title>
      </Head>
      <Container>
        <HeadingWrapper>
          <Heading type="h1">
            {t('404->text')}
            <br />
            {t('404->secondaryText')}
          </Heading>
        </HeadingWrapper>
        <ButtonWrapper>
          <Button type="button" variant="highlight" onClick={() => push(LINKS.START)}>
            {t('404->button->text')}
          </Button>
        </ButtonWrapper>
      </Container>
    </>);
};
// const translations = [...NAMESPACE_REQUIRED.TOOLBAR, ...NAMESPACE_REQUIRED.FOOTER];
async function getStaticProps() {
    const fallback = await createSwrFallback({ pageType: 'ContentPage' });
    return {
        props: {
            fallback,
        },
    };
}
export default Error404;

    async function __Next_Translate__getStaticProps__195d779ee5c__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d779ee5c__ as getStaticProps }
  